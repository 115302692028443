@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.home-layout{
    min-height: 100vh;
    width: 100%;
    background: url('../public/asset/background.png');
    background-size: cover;
    background-position: center;
    overflow: hidden;
    padding-top: 4svh;
}

.loading-img{
    background: url('../public/asset/loading2.jpg');
    background-position: top;
    background-size: cover;
}